<script lang="ts">
    import { getAuctionsByDate } from 'helpers/auctions.helper'
    import Auction from 'modules/auctions/auction-item.svelte'
    import { auctionFilter, auctionsSubscription, maxUpcomingAuctionNumber, activeSubscription, maxFilterDateRange, requestAuctionsFromPage } from 'stores/auction.store'
    import { onDestroy } from 'svelte'
    import { getAddDaysUTC, getTodayUTC } from 'helpers/date.helper'
    import AuctionFilterComponent from 'modules/auctions/auction-filter.svelte'
    import { getUpcomingAuctions } from '_data/_api/auctions/auction.svelte'
    import { getClient } from '@urql/svelte'
    import { browser } from '$app/env'

    const format: AuctionDateFormat = {
        today: "'Today ('dd MMMM yyyy')'",
        upcoming: "EEEE',' dd MMMM yyyy ",
    }

    let auctionsGroupByDate: AuctionDetailByDate[]
    let filteredAuctions: UpcomingAuction[]
    let selectedDatesUTC: string[]
    let datePickerOnChange: boolean
    let selectedDates: string | Date | Date[] | undefined
    const client = getClient()

    if (browser) {
        $requestAuctionsFromPage = 'upcoming'
    }
    onDestroy(() => {
        $auctionsSubscription = null
        $activeSubscription?.unsubscribe()
    })

    function getAuctionsGroupByDate() {
        auctionsGroupByDate = getAuctionsByDate($auctionsSubscription, format)
    }

    function filterAuctions() {
        if (filteredAuctions) auctionsGroupByDate = getAuctionsByDate(filteredAuctions as UpcomingAuction[], format)
    }

    function triggerAPIRequest(from: string, until: string) {
        $auctionFilter = {
            from: from,
            until: until,
            auctionContentType: 0,
            pageSize: $maxUpcomingAuctionNumber,
        } as AuctionFilter

        getUpcomingAuctions($auctionFilter, client)
    }

    function handleSelectedDateChanged() {
        if (selectedDatesUTC && selectedDatesUTC.length > 1) {
            $auctionFilter = {
                from: $auctionFilter?.from as string,
                until: $auctionFilter?.until as string,
                pageSize: $auctionFilter?.pageSize as number,
                auctionContentType: $auctionFilter?.auctionContentType as number,
            }

            triggerAPIRequest(selectedDatesUTC[0], selectedDatesUTC[1])
        }
    }

    $: if ($auctionsSubscription) getAuctionsGroupByDate()
    $: if (filteredAuctions) filterAuctions()

    $: if (selectedDatesUTC) handleSelectedDateChanged()
</script>

<section>
    <AuctionFilterComponent
        rawData={$auctionsSubscription}
        showPastDate={false}
        bind:filteredAuctions
        bind:selectedDates
        bind:selectedDatesUTC
        bind:datePickerOnChange
        defaultDateRange={[getTodayUTC(), getAddDaysUTC($maxFilterDateRange)]}
    />
    {#if auctionsGroupByDate}
        {#if auctionsGroupByDate?.length == 0}
            <h3>
                <span> There are currently no upcoming auctions. Please check back again soon. </span>
            </h3>
        {:else}
            {#each auctionsGroupByDate as auctions}
                {#if auctions.auctions.length > 0}
                    <div>{auctions.auctionDateDisplay}</div>
                    {#each auctions.auctions as auction}
                        <Auction {auction} />
                    {/each}
                {/if}
            {/each}
        {/if}
    {/if}
</section>

<style lang="scss">
    section {
        display: grid;
        max-width: 60vw;
        grid-gap: var(--grid-gap);

        @media (max-width: 1024px) {
            max-width: unset;
            grid-gap: 0.5rem;
        }

        div {
            padding: 1.5rem 1rem 0.7rem;

            font-weight: 800;
            font-size: 2rem;
            line-height: 3.2rem;
        }
    }
</style>
